<template>
  <div class="section">
    <card-component>
      <b-field grouped>
        <b-field :label="$t('def.site')" v-if="!$store.state.user.siteId">
          <b-select expanded size="is-small" v-model="form.siteId">
            <option :value="null">-</option>
            <option :value="item.uuid" v-for="item in sites">{{ item.name }}</option>
          </b-select>
        </b-field>
        <b-field :label="$t('def.bank')">
          <b-select expanded size="is-small" v-model="form.bankId">
            <option :value="null">-</option>
            <option :value="item.uuid" v-for="item in banks">{{ item.name }}</option>
          </b-select>
        </b-field>
        <b-field :label="$t('def.year')">
          <b-select expanded size="is-small" v-model="form.year">
            <option :value="item" v-for="item in years">{{ item }}</option>
          </b-select>
        </b-field>
        <b-field class="is-flex is-align-items-end is-flex-grow-1 is-justify-content-end">
          <b-button size="is-small" icon-left="magnify" type="is-info" @click="getReports">{{$t('def.filter')}}</b-button>
        </b-field>
      </b-field>
    </card-component>
    <card-component>

      <Bar
          :chart-options="chartOptions"
          :chart-data="chartData"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
          :height="height"
      />
    </card-component>
    <card-component v-if="month" :title="`${form.year} ${chartData.labels[month]} Ayı Günlük Yatırım Raporu`">

      <Bar
          :chart-options="chartOptions"
          :chart-data="chartDataDaily"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
          :height="height"
      />
    </card-component>
  </div>
</template>

<script>
import {BankService, ReportService as Service, SiteService} from '@/services'
import {Bar} from 'vue-chartjs/legacy'

import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip} from 'chart.js'
import CardComponent from "@/components/CardComponent.vue";

import {getDaysInMonth} from 'date-fns'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'YearlyDepositReport',
  components: {
    Bar,
    CardComponent
  },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 300
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {
      }
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chartDataDaily: {
        labels: [
        ],
        datasets: [
          {
            label: 'Yatırım',
            backgroundColor: '#48c78e',
            data: []
          },

          {
            label: 'Komisyon',
            backgroundColor: '#0f77ea',
            data: []
          }
        ]
      },
      chartData: {
        labels: [
          'OCAK',
          'ŞUBAT',
          'MART',
          'NİSAN',
          'MAYIS',
          'HAZİRAN',
          'TEMMUZ',
          'AĞUSTOS',
          'EYLÜL',
          'EKİM',
          'KASIM',
          'ARALIK'
        ],
        datasets: [
          {
            label: 'Yatırım',
            backgroundColor: '#48c78e',
            data: []
          },

          {
            label: 'Komisyon',
            backgroundColor: '#0f77ea',
            data: []
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        onClick: this.handle
      },
      form: {
        siteId: this.$store.state.user.siteId,
        bankId: null,
        year: new Date().getFullYear()
      },
      sites: [],
      banks: [],
      years: [],
      month: null
    }
  },
  mounted() {
    for (let i = 2021; i <= new Date().getFullYear(); i++) this.years.push(i)
    if (!this.$store.state.user.siteId) this.getSites()
    this.getBanks()
    this.getReports()
  },
  methods: {
    handle(point,event){
      this.month = event[0].index
      this.getDailyReport(event[0].index)
    },
    getReports() {
      Service.yearlyDeposit(this.form).then(res => {
        const data = res.data
        let deposit = {}
        let commission = {}
        data.map(item => {
          deposit[item.Month - 1] = item.Total
          if (!commission[item.Month - 1]) commission[item.Month - 1] = 0
          commission[item.Month - 1] += parseFloat(item.Commission)
        })
        this.chartData.datasets[0].data = Object.values(deposit)
        this.chartData.datasets[1].data = Object.values(commission)
      })
    },
    getBanks() {
      BankService.all({status: 1}).then(res => this.banks = res.data)
    },
    getSites() {
      SiteService.all({status: 1}).then(res => this.sites = res.data)
    },
    getDailyReport(month) {
      const days = getDaysInMonth(new Date(this.form.year, month))
      this.chartDataDaily.labels = []
      for(let i = 0; i < days; i++) {
        this.chartDataDaily.labels.push(i+1)
      }
      Service.dailyDeposit({...this.form, month: month+1}).then(res => {
        const data = res.data
        let deposit = {}
        let commission = {}
        data.map(item => {
          deposit[item.Day] = item.Total
          if (!commission[item.Day]) commission[item.Day] = 0
          commission[item.Day] += parseFloat(item.Commission)
        })
        this.chartDataDaily.datasets[0].data = Object.values(deposit)
        this.chartDataDaily.datasets[1].data = Object.values(commission)
      })
    },
  }
}
</script>
